<template>
    <v-container fluid>
        <v-alert dense outlined type="error" v-if="Object.keys(validation_errors).length > 0">
            <ul>
                <li v-for="(errors, field) in validation_errors" :key="field">
                    {{ field }}
                    <ul>
                        <li v-for="(error, sl) in errors" :key="sl">
                            {{ error }}
                        </li>
                    </ul>
                </li>
            </ul>
        </v-alert>

        <v-row>
            <v-col cols="12" class="py-1">
                <v-card :elevation="1">
                    <v-toolbar color="white" :elevation="1" height="30px">
                        <v-toolbar-title class="subtitle-2">Department Entry</v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="py-0">
                        <v-form @submit.prevent="saveDepartment" ref="departmentForm" class="custom-form">
                            <v-row class="justify-center">
                                <v-col cols="4" offet="4">
                                    <v-row>
                                        <v-col cols="4" class="py-1 caption text-end">Name</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details :rules="[(v) => !!v]"
                                                v-model.trim="department.name"
                                                :error-messages="validation_errors.name"></v-text-field>
                                        </v-col>
                                        <v-col cols="4" class="py-1 caption text-end">BN Name</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details :rules="[(v) => !!v]"
                                                v-model.trim="department.bn_name"
                                                :error-messages="validation_errors.bn_name"></v-text-field>
                                        </v-col>

                                        <v-col cols="4" class="py-1 caption text-end">Details</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details :rules="[(v) => !!v]"
                                                v-model.trim="department.details"
                                                :error-messages="validation_errors.details"></v-text-field>
                                        </v-col>

                                        <v-col cols="4" class="py-1 caption text-end">Rank</v-col>
                                        <v-col cols="8" class="py-0">
                                            <v-text-field dense outlined hide-details type="number" min="0"
                                                :rules="[(v) => !!v]" v-model.number="department.rank"
                                                :error-messages="validation_errors.rank"></v-text-field>
                                        </v-col>

                                        <v-col cols="12" class="py-0 text-end">                                            
                                            <v-btn height="26px" class="mr-1" dark color="deep-orange" :elevation="1"
                                                @click="resetForm">Cancel</v-btn>
                                                <v-btn type="submit" height="26px" dark color="light-blue darken-2"
                                                :elevation="1" :loading="loading">Save</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" class="pb-0">
                <v-data-table class="custom-data-table elevation-1" dense :headers="departmentHeaders"
                    :items="$store.getters['department/departments']" :search="searchDepartment">
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Department List</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field outlined dense hide-details placeholder="Search Department"
                                    append-icon="mdi-magnify" style="width: 300px" v-model="searchDepartment">
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip bottom v-if="$store.state['user'].role != 'User'">
                            <template v-slot:activator="{ on }">
                                <v-icon small @click="editDepartment(item)" color="primary"
                                    v-on="on">mdi-circle-edit-outline</v-icon>
                            </template>
                            <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="$store.state['user'].role == 'Admin'">
                            <template v-slot:activator="{ on }">
                                <v-icon small @click="deleteDepartment(item)" color="error"
                                    v-on="on">mdi-delete-circle-outline</v-icon>
                            </template>
                            <span>Delete</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "Departments",

    data() {
        return {
            departmentHeaders: [
                { text: "Name", value: "name" },
                { text: "BN Name", value: "bn_name" },
                { text: "Details", value: "details" },
                { text: "Rank", value: "rank" },
                { text: "Action", value: "action" },
            ],
            searchDepartment: "",

            department: {
                name: "",
                bn_name: "",
                details: "",
                rank: null,
            },
            editId: null,
            validation_errors: {},
            loading: false,
        };
    },

    created() {
        this.$store.dispatch("department/getDepartments");
    },

    methods: {
        async saveDepartment() {
            if (!this.$refs.departmentForm.validate()) return;

            this.loading = true;

            let data = {
                url: "/add-department",
                department: this.department,
            };

            if (this.editId) {
                data.url = "/update-department";
                data.department.id = this.editId;
            }

            let resObj = await this.$store.dispatch(
                "department/saveDepartment",
                data
            );

            if (resObj.hasOwnProperty("validation_errors")) {
                this.validation_errors = resObj.validation_errors;
            }
            this.loading = false;

            if (resObj.isSuccess) {
                this.resetForm();
            }

        },
        editDepartment(department) {
            Object.keys(this.department).forEach(
                (k) => (this.department[k] = department[k])
            );

            this.editId = department.id;
        },
        deleteDepartment(department) {
            if (!confirm("Are you sure?")) return;

            this.$store.dispatch("department/deleteDepartment", department.id);
        },
        resetForm() {
            Object.keys(this.department).forEach(
                (k) => (this.department[k] = "")
            );

            this.editId = null;
            this.$refs.departmentForm.resetValidation();
            this.validation_errors = {};
        },
    },
};
</script>

<style></style>
